import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';

import { passwordRegEx } from '@design/forms/form-validation';

/**
 * Reusable partial used by initial sign-in password reset and forgot password flows.
 * Appends its password controls to the first FormGroup it finds in its ancestor hierarchy.
 */
@Component({
  selector: 'app-password-reset-controls',
  templateUrl: './password-reset-controls.component.html',
  styleUrls: ['./password-reset-controls.component.scss'],
  standalone: false
})
export class PasswordResetControlsComponent implements OnInit {
  formGroup: UntypedFormGroup;
  newPasswordControl = new UntypedFormControl(null, [
    Validators.required,
    Validators.pattern(passwordRegEx),
    PasswordResetControlsComponent.confirmPasswordValidator
  ]);

  confirmPasswordControl = new UntypedFormControl(null, [Validators.required, PasswordResetControlsComponent.confirmPasswordValidator]);

  // used by the password strength meter
  password$ = this.newPasswordControl.valueChanges;

  private static confirmPasswordValidator(validateControl: AbstractControl) {
    if (!validateControl.parent) return;

    const confirmControl = validateControl.parent.get('confirmPassword');

    if (!confirmControl) return;

    const passwordControl = validateControl.parent.get('password');

    if (!(confirmControl.dirty && passwordControl.dirty)) return;

    const password = passwordControl.value;
    const passwordConfirmation = confirmControl.value;

    const valid = password === passwordConfirmation;

    if (valid) {
      [passwordControl, confirmControl].forEach((ctrl) => {
        const errors = { ...ctrl.errors };
        delete errors.passwordsMismatch;
        ctrl.setErrors(Object.keys(errors).length ? errors : null);
      });
    }

    return valid ? null : { passwordsMismatch: true };
  }

  constructor(private formGroupDirective: FormGroupDirective) {}

  ngOnInit() {
    const formGroup = this.formGroupDirective.control;
    formGroup.addControl('password', this.newPasswordControl);
    formGroup.addControl('confirmPassword', this.confirmPasswordControl);
    this.formGroup = formGroup;
  }

  get password(): string {
    return this.newPasswordControl.value;
  }

  passwordConformsTo(param: string) {
    let pattern = /()/;

    if (this.newPasswordControl.value != null) {
      switch (param) {
        case 'min-length':
          return this.newPasswordControl.value.length > 7;
          break;
        case 'upper-case-letter':
          pattern = /(?=.*[A-Z])/;
          break;
        case 'lower-case-letter':
          pattern = /(?=.*[a-z])/;
          break;
        case 'digit':
          pattern = /(?=.*[0-9])/;
          break;
        case 'special-char':
          pattern = /(?=.*[!@#$%^&*?-])/;
          break;
        default:
          throw 'parameter: ' + param + ' not supported';
      }

      return pattern.test(this.newPasswordControl.value);
    } else {
      return false;
    }
  }
}
