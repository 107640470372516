import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@auth-n/authentication.service';
import { PasswordResetControlsComponent } from '@auth-n/password-reset-controls/password-reset-controls.component';
import { ActiveAuthenticationStateService } from '@auth-n/state/active-authentication.state-service';
import { LoggingService } from '@logging/logging.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ExtrasState } from '@utils/route-utils';

@UntilDestroy()
@Component({
  selector: 'app-sign-in-flow',
  templateUrl: './sign-in-flow.component.html',
  styleUrl: './sign-in-flow.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class SignInFlowComponent implements OnInit {
  message: {
    title: string;
    body?: string;
  };

  fetching$ = this.authenticationState.inFlight$;

  // sign-in form
  emailControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  signInFormGroup: UntypedFormGroup = new UntypedFormGroup({
    username: this.emailControl
  });

  // password form
  passwordUsername = new UntypedFormControl('', [Validators.required]);
  passwordControl = new UntypedFormControl('', [Validators.required]);
  passwordSignInGroup: UntypedFormGroup = new UntypedFormGroup({
    username: this.passwordUsername,
    password: this.passwordControl
  });

  // mfa form
  mfaCodeControl = new UntypedFormControl('', [Validators.required]);
  mfaFormGroup: UntypedFormGroup = new UntypedFormGroup({
    mfaCode: this.mfaCodeControl
  });

  // complete password form
  confirmPasswordFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  @ViewChild('passwordResetControls') passwordResetControls: PasswordResetControlsComponent;

  constructor(
    public authenticationState: ActiveAuthenticationStateService,
    private logger: LoggingService,
    private authenticationService: AuthenticationService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.message = (<ExtrasState>route.snapshot.data).extrasStateResolver?.message;
  }

  ngOnInit(): void {
    this.authenticationState.signInMfaOptions = undefined;
    this.authenticationState.completePasswordOptions = undefined;
  }

  signIn() {
    this.clearMessage();
    if (!this.signInFormGroup.valid) return;

    this.passwordUsername.setValue(this.emailControl.value);
    this.authenticationService.getSignInJourney(this.emailControl.value.toLowerCase());
  }

  signInPassword() {
    this.clearMessage();
    if (!this.signInFormGroup.valid) return;

    this.authenticationService
      .userSignIn(this.emailControl.value.toLowerCase(), this.passwordControl.value)
      .then(() => this.logger.trace('SignInJourney: signed-in'));
  }

  signInMfa() {
    if (!this.mfaFormGroup.valid) return;

    this.authenticationService.signInMfa(this.mfaCodeControl.value).then(() => this.logger.trace('SignInComponent: signed-in mfa'));
  }

  completeNewPassword() {
    if (!this.confirmPasswordFormGroup.valid) return;

    this.authenticationService
      .completeNewPassword(this.authenticationState.completePasswordOptions.username, this.passwordResetControls.password)
      .then(() => this.logger.trace('SignInComponent: completed new password'));
  }

  cancel() {
    this.authenticationState.completePasswordOptions = undefined;
    this.authenticationState.signInMfaOptions = undefined;
    this.authenticationState.signInJourney = undefined;
    this.mfaCodeControl.setValue('');
  }

  clearMessage() {
    this.message = null;
  }

  requestResetPassword() {
    this.router.navigate(['/auth', { outlets: { top: ['request-reset-password'] } }]).then();
  }

  goToHelp() {
    window.open('https://cintra.freshdesk.com/support/home', '_blank').focus();
  }
}
