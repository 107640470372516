import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PasswordResetControlsComponent } from '../password-reset-controls/password-reset-controls.component';
import { OnscreenMessagingService } from '@pattern-library/onscreen-messaging/onscreen-messaging.service';
import { updatePassword, type UpdatePasswordInput } from 'aws-amplify/auth';
import { ActivatedRoute } from '@angular/router';
import { AbstractReturnToReferrerComponent } from '@auth-n/abstract-return-to-referrer.component';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
  standalone: false
})
export class ChangePasswordDialogComponent extends AbstractReturnToReferrerComponent {
  currentPasswordControl = new UntypedFormControl('', [Validators.required]);
  resetPasswordFormGroup: UntypedFormGroup = new UntypedFormGroup({
    currentPassword: this.currentPasswordControl
  });
  @ViewChild('passwordResetControls') passwordResetControls: PasswordResetControlsComponent;

  constructor(
    private messagingService: OnscreenMessagingService,
    public route: ActivatedRoute
  ) {
    super();
    this.setBackgroundStyle();
  }

  changePasswordSubmit() {
    if (!this.resetPasswordFormGroup.valid) return;

    const request: UpdatePasswordInput = {
      oldPassword: this.currentPasswordControl.value,
      newPassword: this.passwordResetControls.newPasswordControl.value
    };

    updatePassword(request)
      .then(() => {
        this.close();
        this.messagingService.success('Password successfully updated.');
      })
      .catch((err) => {
        if (err.code === 'NotAuthorizedException' && err.message === 'Incorrect username or password.') {
          this.messagingService.warn('Your existing password was incorrect.');
          return;
        }
        if (err.code === 'InvalidParameterException' && err.message.includes('previousPassword')) {
          this.messagingService.warn('Your existing password was incorrect.');
          return;
        }
        if (err.code === 'InvalidParameterException') {
          this.messagingService.warn('Please ensure your password meets the strength criteria.');
          return;
        }
        throw err;
      });
  }
}
