import { Component, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { PasswordResetControlsComponent } from '../password-reset-controls/password-reset-controls.component';
import { OnscreenMessagingService } from '@pattern-library/onscreen-messaging/onscreen-messaging.service';
import { authenticationRoutes } from '../authentication-routes';
import { ResetPasswordService } from './reset-password.service';
import { ActiveAuthenticationStateService } from '@auth-n/state/active-authentication.state-service';
import { LoggingService } from '@logging/logging.service';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
  providers: [ResetPasswordService],
  standalone: false
})
export class ResetPasswordFormComponent {
  inFlight$ = this.authenticationState.inFlight$;

  emailControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  resetPasswordRequestFormGroup: UntypedFormGroup = new UntypedFormGroup({
    email: this.emailControl
  });

  token = this.route.snapshot.queryParams['t'];

  currentStep: 'RequestToken' | 'CompleteResetPassword' = !this.token ? 'RequestToken' : 'CompleteResetPassword';

  resetPasswordFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  @ViewChild('passwordResetControls') passwordResetControls: PasswordResetControlsComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messagingService: OnscreenMessagingService,
    private resetPasswordService: ResetPasswordService,
    private authenticationState: ActiveAuthenticationStateService,
    private logger: LoggingService
  ) {
    logger.trace('ResetPasswordFormComponent - token: ', this.token);
  }

  requestResetPassword() {
    if (!this.resetPasswordRequestFormGroup.valid) return;

    const email = this.emailControl.value.toLowerCase();
    this.resetPasswordService.requestResetPassword$(email).subscribe(
      (_) => {
        this.router
          .navigateByUrl(authenticationRoutes.signIn, {
            state: {
              message: {
                title: 'Please check your email for instructions on how to regain access to your account.'
              }
            }
          })
          .then();
      },
      (_) => {
        this.messagingService.warn('An unexpected error occurred.');
      }
    );
  }

  completeResetPassword() {
    if (!this.resetPasswordFormGroup.valid) return;

    this.resetPasswordService
      .resetPassword$(this.token, this.passwordResetControls.password)
      .pipe(
        catchError((error) => {
          console.error(error);
          const status = error.status;
          if (status === 400) {
            this.messagingService.openAlertDialog({
              title: 'Invalid token',
              message: 'The reset password token is no longer valid.\nYou may wish to restart resetting your password.'
            });
            return of(null);
          } else if (status === 500) {
            this.messagingService.warn(
              'There was an error processing the request.\n\nPlease try again later or contact your administrator.'
            );
            return of(null);
          }
          return throwError(() => error);
        })
      )
      .subscribe((_) => {
        this.router.navigateByUrl(authenticationRoutes.signIn).then();
      });
  }

  cancel() {
    this.router.navigate(['/auth', { outlets: { top: ['sign-in'] } }]).then();
  }
}
