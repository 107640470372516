import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PasswordInputComponent {
  constructor() {}

  @Input() formPropertyName: string;
  @Input() placeholder: string;
  @Input() tId: string;
  @Input() label: string;

  passwordType = 'password';
  passwordIcon = 'visibility_off_outline';

  togglePasswordVisibility() {
    if (this.passwordType == 'password') {
      this.passwordType = 'text';
      this.passwordIcon = 'visibility_outline';
    } else {
      this.passwordType = 'password';
      this.passwordIcon = 'visibility_off_outline';
    }
  }
}
